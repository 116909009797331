<template>
  <div class="navigation app bg-secondary h-[100vh] w-full">
    <div
      class="p-2 flex flex-col h-full justify-between absolute bottom-0 w-full overflow-auto"
    >
      <header class="relative px-5">
        <div class="store-logo-container flex justify-center items-center max-h-14 h-14">
          <NavigationStoreLogo />
        </div>
      </header>
      <PopUpsStoreFinder
        :isAppDialog="true"
        :title="useCurrentStore()?.value?.id != 'e_2879130' ? defaultStoreText : centralStoreText"
        @storeChanged="$emit('closeDialog'); /*ensurePermissions('PUSH')*/"
      />
    </div>
  </div>
</template>
<script setup>
import { useCurrentStore } from "~/composables/states";
//const { ensurePermissions } = usePushNotificationUtils();

const currentStore = ref(useCurrentStore());
const centralStoreText = 'Bitte wähle einen Fachmarkt aus. Fachmärkte findest du über die Suche nach Postleitzahl, Stadt oder im Umkreis in deiner Nähe.';
const defaultStoreText = 'Sie wurden automatisch dem Fachmarkt ' + currentStore?.value.name + ' zugeordnet. <br /> Falls Sie einen anderen Fachmarkt wünschen, wählen Sie diesen unten aus.';
</script>
