<template>
  <div v-if="shouldDisplay()?.value == true" class="debugContent">
    <slot />
  </div>
</template>

<script lang="ts" setup>

function shouldDisplay() {
  return mayShowDebugContent()
}

</script>

<style>
.debugContent {
  border: 1px gray dotted;
  overflow: auto;
}
</style>