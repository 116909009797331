<template>
  <div :class="'theme-' + contentTheme">
    <NuxtPwaManifest />
    <ClientOnly>
      <Modal
        v-if="showRatingRationale && useCurrentStore()?.value?.id != 'e_2879130' && !useAppSettingsCookie().value.hasAskedForRating">
        <AppPermissionRationale :permission-type="'RATING'" agree-label="Bewertung abgeben"
          later-label="Feedback abgeben" @agreed="sendToAppStore" @skipped="sendAppFeedback"
          @closed="showRatingRationale = !showRatingRationale" />
      </Modal>
      <div :class="'theme-' + layoutTheme" v-if="dialog != 'NONE'">
        <AppLogin @close-login="dialog = 'NONE'" @open-register="dialog = 'REGISTER'" @back-to-intro="dialog = 'INTRO'"
          @navigate-modal="navigateAppModal" v-if="dialog == 'LOGIN'" />
        <AppIntro @close-intro="dialog = 'NONE'" @access-login="dialog = 'LOGIN'" @navigate-modal="navigateAppModal"
          v-if="dialog == 'INTRO'" />
        <AppStoreAssignment @close-dialog="dialog = 'NONE'" @navigate-modal="navigateAppModal"
          v-if="dialog == 'STOREFINDER'" />
        <AppRegister @close-intro="dialog = 'NONE'" @back-to-login="dialog = 'LOGIN'" @navigate-modal="navigateAppModal"
          v-if="dialog == 'REGISTER'" />
        <AppPasswordRecover @back-to-login="dialog = 'LOGIN'" @navigate-modal="navigateAppModal"
          v-if="dialog == 'PASSWORDRECOVER'" />
      </div>
    </ClientOnly>
    <div v-if="dialog == 'NONE'">
      <LayoutAppHeader :class="'theme-' + layoutTheme" />
      <main class="app">
        <slot />
      </main>
      <BasicBtErrorBoundary>
        <BtDevOnly>
          <p>Version Info:</p>
          <pre>{{ useRuntimeConfig().public.version }}</pre>
          <p>Flagsmith State:
          <pre>
            {{ $flags?.getState() }}
          </pre>
          </p>
        </BtDevOnly>
      </BasicBtErrorBoundary>
      <BasicBtErrorBoundary>
        <LayoutAppFooter @navigate-modal="navigateAppModal" :class="'theme-' + layoutTheme" />
      </BasicBtErrorBoundary>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
import { useCurrentStore, useAppSettingsCookie } from "~/composables/states";
import { sendToAppStore, sendAppFeedback } from "~/composables/miscFunctions";
const { $pwa } = useNuxtApp();

const showRatingRationale = useState('showRatingRationale', () => false);

if ($pwa?.needRefresh || $pwa?.registrationError) {
  console.warn('updating service worker');
  await $pwa.updateServiceWorker(true);
}

const dialog = ref("NONE");
const contentTheme = ref('default');
const layoutTheme = ref('default');
function getSeasonalTheme() {
  if (useFeatureState('cfg-blackweek-header')?.value == true) {
    return 'blackweek'
  }
}

onNuxtReady(async () => {
  watch(() => useRoute()?.fullPath, async () => {
    layoutTheme.value = getSeasonalTheme();
    if (useRoute().path.endsWith('/app')) {
      contentTheme.value = getSeasonalTheme();
    } else {
      contentTheme.value = 'default';
    }
  }, { deep: true, immediate: true });
});

useHead({
  meta: [
    {
      id: "viewport",
      name: "viewport",
      content:
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
  ],
});
const currentTopicAmount: any = ref('0');

provide('currentTopicAmount', currentTopicAmount)

onNuxtReady(async () => {
  changeTopicAmount();
})

async function changeTopicAmount() {
  const { data: amount } = await useFetch("/api/nea/topic-service/api/topics/storeId/" + useCurrentStore()?.value?.id + "/count", {
    query: { active: true }
  });
  currentTopicAmount.value = amount;
}

watchEffect(async () => {
  if (useCurrentStore()?.value?.id != 'e_2879130') {
    dialog.value = "NONE";
    changeTopicAmount();
  } else {
    dialog.value = "STOREFINDER";
  }
})

onNuxtReady(() => {
  try {
    // useNuxtApp()?.$flags?.setTrait("isAppUser", true);
  } catch (error) {
    console.error("could not set flagsmith trait", error);
  }
  if (useRoute()?.query?.showRatingRationale == "true") {
    showRatingRationale.value = true;
  }
});

useAppConfig().homelink = "/app";
// useAppConfig().homelinkExternal = false;
useAppConfig().isAppUser = true;
function navigateAppModal(n: string) {
  dialog.value = n || "NONE";
}
onMounted(() => {
  layoutTheme.value = getSeasonalTheme();
});

</script>

<style>
main.app {
  padding-top: 135px;
  padding-bottom: 100px;
}

header.theme-blackweek {
  @apply bg-black;
}

.theme-blackweek header.app {
  @apply bg-black;
}

.theme-blackweek header.app .store-logo-container {
  @apply bg-black;
}

.theme-blackweek .modal {
  @apply bg-black text-white;
}

.theme-blackweek .modal .bg-secondary {
  @apply text-white bg-[#212121];}

header.app.theme-blackweek .store-logo-container {
  @apply bg-black;
}

.theme-blackweek footer {
  @apply text-white bg-[#212121];
}

.theme-blackweek main.app {
  @apply bg-black text-white;
}

.theme-blackweek main.app .topic-package {
  @apply text-white bg-[#393939] border-[#555555] rounded-b;
}

.theme-blackweek main.app .topic-preview {
  @apply text-white bg-[#393939] rounded-b;
}

.theme-blackweek main.app .topic-preview .entry {
  @apply text-white border-[#555555];
}

.theme-blackweek main.app .topic-preview .entry:last-child {
  @apply text-white border-[#555555];
}

.theme-blackweek .storefinder.teaser {
  @apply text-white bg-[#393939] bg-opacity-40;
}

.theme-blackweek .information-teaser {
  @apply text-white bg-[#393939];
}

.theme-blackweek main.app {
  @apply min-h-screen;
}
</style>
