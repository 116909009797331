<template>
  <div class="bg-white">
    <AppLogo />
    <div v-if="requestSend == false" class="p-2 flex flex-col h-full">
      <form @submit.prevent="passwordRevovery($event)" class="flex flex-col gap-4">
        <div class="text-2xl border-b font-bold py-2">Passwort vergessen?</div>
        <div class="flex flex-col gap-3">
          <div class="text-lg py-2 font-bold">Passwort hier anfordern</div>
          <div class="text-sm">
            Bitte geben Sie unten ihre E-Mail-Adresse ein und wir senden Ihnen einen
            zeitlich begrenzten Link, um Ihr Passwort zu ändern
          </div>
          <div>
            <input
              v-model="email"
              class="inp mail"
              type="text"
              required
              placeholder="E-Mail-Adresse*"
            />
          </div>
          <div>
            <button type="submit" class="text-center btn-primary block w-full font-bold">
              Passwort zurücksetzen
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="flex flex-col gap-4 p-2">
      <header>
        <div
          class="store-logo-container flex justify-center items-center max-h-14 h-14 bg-white"
        >
          <img
            src="https://cdn.expert.de/47/f1/37/344581753cb59b3dfbe24d781b76f70899/expert_logo.svg"
            class="h-full"
            alt="Logo"
          />
        </div>
      </header>
      <div class="text-success font-bold">
        Sie erhalten in wenigen Augenblicken eine E-Mail mit einem Link zum Zurücksetzen
        Ihres Passworts.
      </div>
      <div>Keine Nachricht erhalten?</div>
      <ol class="list-decimal p-4 flex gap-2 flex-col">
        <li>Überprüfen Sie den Spam-Ordner.</li>
        <li>Überprüfen Sie Ihre Angaben.</li>
        <li>
          Sollten Sie keine Nachricht erhalten haben, besteht zu der eingegebenen
          E-Mail-Adresse kein Kundenkonto. Erstellen Sie
          <span class="text-primary" @click="$emit('navigateModal', 'REGISTER')">hier</span>
          ein Kundenkonto.
        </li>
      </ol>
    </div>
    <div class="text-center p-4 text-sm" @click="$emit('BackToLogin')">Zurück</div>
  </div>
</template>
<script setup>
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
const email = ref("");
const requestSend = ref(false);
if (!useCurrentUserState()?.value?.csrfToken) await getExpUserProfile();
async function passwordRevovery() {
  await useFetch("/api/neo/frontend/_api/user/requestPasswordReset", {
    method: "POST",
    headers: {
      "bt-use-user-auth": "true",
      "csrf-token": useCurrentUserState()?.value?.csrfToken,
    },
    body: {
      email: email,
    },
  });
  requestSend.value = true;
}
</script>
