<template>
  <div class="p-2 flex flex-col h-[100vh] justify-between w-full">
    <swiper-container
      slides-per-view="1"
      class="w-full h-full"
      navigation-next-el=".custom-next-button"
      navigation-prev-el=".custom-prev-button"
      pagination-clickable="true"
      pagination-dynamic-bullets="true"
      long-swipes="false"
    >
      <swiper-slide v-for="entry in content" :key="entry.id">
        <div class="flex flex-col justify-between h-full pb-10 overflow-auto">
          <div class="flex flex-col gap-4">
            <header class="relative">
              <div
                class="store-logo-container flex justify-center items-center max-h-14 h-14 bg-white"
              >
                <img
                  src="https://cdn.expert.de/47/f1/37/344581753cb59b3dfbe24d781b76f70899/expert_logo.svg"
                  class="h-full"
                  alt="Logo"
                />
              </div>
            </header>
            <img
              class="mx-auto w-full pointer-events-none"
              :src="entry.img"
              alt="Exklusive Angebote & Coupons"
            />
            <div class="text-3xl font-extrabold" v-text="entry.headline" />
            
            <div class="text-sm" v-text="entry.text" />
            <div class="text-sm">Version: {{ useRuntimeConfig().public.version }}</div>
          </div>
          <div class="py-4">
            <div
              class="bg-primary text-primary-contrast px-4 py-3 w-full text-center font-bold text-sm"
              @click="$emit('accessLogin')"
            >
              Einloggen oder Registrieren
            </div>
            <div
              class="bg-primary text-primary-contrast mt-4 px-4 py-3 w-full text-center font-bold text-sm"
              @click="$emit('navigateModal', 'NONE');"
            >
              Zurück zur Startseite
            </div>
          </div>
        </div>

      </swiper-slide>
    </swiper-container>
    <div className="nav-btn custom-prev-button">

    </div>

    <div className="nav-btn custom-next-button">
      
    </div>
  </div>
</template>
<script setup>
import step1 from "~/assets/img/Gruppe 84.jpg";
import step2 from "~/assets/img/Gruppe 85.jpg";
import step3 from "~/assets/img/Gruppe 86.jpg";
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register()

// import Swiper styles
const activeSlide = ref(0);
const emit = defineEmits(["closeIntro"]);

const content = [
  {
    id: "1",
    img: step1,
    headline: "Exklusive Angebote & Coupons",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu tortor gravida, vehicula leo et, consectetur risus. ",
  },
  {
    id: "2",
    img: step2,
    headline: "Schnell & bequem Shoppen",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu tortor gravida, vehicula leo et, consectetur risus. Quisque vitae rhoncus mi, et pretium ex. Aliquam lobortis diam nec risus dapibus, vel accumsan libero ullamcorper.",
  },
  {
    id: "3",
    img: step3,
    headline: "Verpasse keine neuen Produkte & Aktionen",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu tortor gravida, vehicula leo et, consectetur risus. Quisque vitae rhoncus mi, et pretium ex. Aliquam lobortis diam nec risus dapibus, vel accumsan libero ullamcorper.",
  },
];
</script>
<style>

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  @apply h-4 w-4 rounded-full border-primary border;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-primary;
}

.slider-main-container {
  position: relative;
}

.nav-btn {
  display: block;
  position: absolute;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  top: 28px;
}
.nav-btn.custom-prev-button {
  left: 2em;
  display:none;
}
.nav-btn.custom-next-button {
  right: 0.50em;
}

:root {
  --swiper-pagination-color: rgb(var(--color-primary));
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 16px;
  --swiper-pagination-bullet-height: 16px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

</style>
